"use strict";

import Swiper, { Navigation, Scrollbar, Autoplay } from 'swiper';
import 'swiper/less';
import 'swiper/less/navigation';
import 'swiper/less/scrollbar';

function padZero(number = 0) {
	if (number.toString().length < 2) {
		return "0" + number.toString();
	}
	return number;
}

function renderPaginationContents(swiper) {
	const currentSlide = swiper.slides[swiper.realIndex];
	const desc = currentSlide.dataset['desc'];

	let toReturn = "<b>" + padZero(swiper.realIndex + 1) + '<span class="text-other"> / ' + padZero(swiper.slides.length) + '</span></b>';
	if (desc) {
		toReturn = toReturn + ' <div class="long-dash"></div> ' + desc;
	}
	return toReturn;
}

function updatePagination(swiper) {
	const target = swiper.el.querySelector('.swiper-pagination');
	target.innerHTML = renderPaginationContents(swiper);
}

window.addEventListener('load', function() {
	new Swiper('.swiperLogos', {
		modules: [Autoplay],
		loop: true,
		slidesPerView: "auto",
		centeredSlides: true,
		spaceBetween: 80,
		autoplay: {
			delay: 3000,
			disableOnInteraction: true,
		}
	});
});

new Swiper('.swiperSide', {
	modules: [Navigation, Autoplay],
	loop: false,
	autoplay: {
		delay: 7000,
		disableOnInteraction: true,
	},

	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},

	on: {
		init: function(swiper) {
			updatePagination(swiper);
		},
		slideChange: function(swiper) {
			updatePagination(swiper);
		},
	},
});

new Swiper('.swiperMenu', {
	modules: [Navigation, Scrollbar],
	loop: false,
	slidesPerView: "auto",

	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},

	scrollbar: {
		el: '.swiper-scrollbar',
		// hide: true,
	},
});
