"use strict";

//import "./slideOut";
import "./swiper";
import "./countUp";
import "./menu";
import "./scroll";


document.querySelectorAll('.accordion a.openCloseIcon').forEach(function(value, key, parent) {
	value.addEventListener('click', function(event) {
		event.preventDefault();
		console.log(this);
		this.parentElement.classList.toggle('opened');
		this.classList.toggle('opened');
	});
})
