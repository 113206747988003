import {throttle} from "throttle-debounce";

const side = document.getElementById('services');
const menu = document.getElementById('menuObserver');
const header = document.getElementById('headerWrapper');

function updateSide() {
	if (side) {
		let padTop = 20;
		let topPosition = menu.getBoundingClientRect().top - header.offsetHeight - padTop;
		let bottomPosition = topPosition + menu.offsetHeight;
		let stickyTop = topPosition > 0;
		let stickyBottom = bottomPosition > 0;

		if (stickyBottom) {
			let offset = topPosition;
			if (topPosition > 0) {
				offset = 0;
			}

			offset = Math.abs(offset);

			if (offset > menu.offsetHeight - side.offsetHeight) {
				offset = menu.offsetHeight - side.offsetHeight;
			}

			side.style.top = offset + 'px';
		}

		if (stickyTop) {
			side.style.top = '0px';
		}
	}
}

const scrollHeader = document.getElementById('scrollHeader');
const scrollHeaderUpper = document.getElementById('scrollHeaderUpper');

function updateHeader() {
	if (scrollHeader && scrollHeaderUpper) {
		let offset = scrollHeaderUpper.getBoundingClientRect().top - header.offsetHeight - 60;
		if (offset < 0) {
			offset = 0;
		}

		if (offset > 400) {
			offset = 400;
		}

		//console.log(topUpper ,offset);

		scrollHeader.style.marginTop = offset + 'px';
	}
}

const scrollLeft = document.getElementById('scrollLeft');
function updateLeft() {
	if (scrollLeft) {
		const size = scrollLeft.getBoundingClientRect();
		let topUpper = size.top - header.offsetHeight;

		let percent = ((topUpper + size.height) / window.innerHeight) * 100;

		let ap = (percent - 50) * 1.2;
		if (ap < 0) {
			ap = 0;
		}
		if (ap > 100) {
			ap = 100;
		}

		//let freeSpace = window.innerWidth - size.width - 64;
		let freeSpace = -window.innerWidth;

		if (ap >= 0 && ap <= 100) {
			scrollLeft.style.left = freeSpace * (ap / 100) + 'px';
		} else {
			scrollLeft.style.left = 0 + 'px';
		}

		//console.log(topUpper, percent, ap, freeSpace);
	}
}

function tick() {
	updateSide();
	updateHeader();
	updateLeft();
}

document.addEventListener('DOMContentLoaded', tick);

window.addEventListener('scroll', throttle(8, tick));
window.addEventListener('resize', throttle(16, tick));